import { EnvironmentUrls } from 'Roblox';
import { urlService } from 'core-utilities';

const { economyApi } = EnvironmentUrls;

export default {
  getRobuxUpgradesUrl: source =>
    urlService.getUrlWithQueries('/Upgrades/Robux.aspx', { ctx: source }),
  getAvatarPageUrl: () => urlService.getAbsoluteUrl('/my/avatar'),
  getPurchaseItemUrl: productId => `${economyApi}/v1/purchases/products/${productId}`
};
