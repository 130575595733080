export default {
  errorTypeIds: {
    transactionFailure: 'TransactionFailureView',
    insufficientFunds: 'InsufficientFundsView',
    priceChanged: 'PriceChangedView'
  },
  errorStatusText: {
    badRequest: 'Bad Request'
  },
  events: {
    startItemPurchase: 'ItemPurchaseStart'
  },
  transactionVerbs: {
    bought: 'bought',
    rented: 'rented',
    renewed: 'renewed'
  },
  resources: {
    freeLabel: 'Label.Free',
    okAction: 'Action.Ok',
    insufficientFundsHeading: 'Heading.InsufficientFunds',
    insufficientFundsMessage: 'Message.InsufficientFunds',
    cancelAction: 'Action.Cancel',
    buyRobuxAction: 'Action.BuyRobux',
    buyAccessAction: 'Action.BuyAccess',
    buyItemHeading: 'Heading.BuyItem',
    rentNowAction: 'Action.RentNow',
    buyNowAction: 'Action.BuyNow',
    rentItemHeading: 'Heading.RentItem',
    getItemHeading: 'Heading.GetItem',
    getNowAction: 'Action.GetNow',
    priceChangedHeading: 'Heading.PriceChanged',
    priceChangedMessage: 'Message.PriceChanged',
    balanceAfterMessage: 'Message.BalanceAfter',
    agreeAndPayLabel: 'Label.AgreeAndPay',
    promptRentAccessMessage: 'Message.PromptRentAccess',
    promptRentMessage: 'Message.PromptRent',
    promptGetFreeAccessMessage: 'Message.PromptGetFreeAccess',
    promptGetFreeMessage: 'Message.PromptGetFree',
    promptBuyAccessMessage: 'Message.PromptBuyAccess',
    promptBuyMessage: 'Message.PromptBuy',
    configureAction: 'Action.Customize',
    notNowAction: 'Action.NotNow',
    customizeAction: 'Action.Customize',
    continueAction: 'Action.Continue',
    purchaseCompleteHeading: 'Heading.PurchaseComplete',
    successfullyBoughtAccessMessage: 'Message.SuccessfullyBoughtAccess',
    successfullyBoughtMessage: 'Message.SuccessfullyBought',
    successfullyRentedAccessMessage: 'Message.SuccessfullyRented',
    successfullyRenewedAccessMessage: 'Message.SuccessfullyRenewedAccess',
    successfullyRenewedMessage: 'Message.SuccessfullyRenewed',
    successfullyAcquiredAccessMessage: 'Message.SuccessfullyAcquiredAccess',
    successfullyAcquiredMessage: 'Message.SuccessfullyAcquired',
    errorOccuredHeading: 'Heading.ErrorOccured',
    purchasingUnavailableMessage: 'Message.PurchasingUnavailable'
  }
};
