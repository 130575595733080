import React from 'react';
import PropTypes from 'prop-types';
import { renderToString } from 'react-dom/server';
import { withTranslations } from 'react-utilities';
import { createModal } from 'react-style-guide';
import translationConfig from '../translation.config';
import itemPurchaseConstants from '../constants/itemPurchaseConstants';
import urlConstants from '../constants/urlConstants';
import PriceLabel from '../components/PriceLabel';

const { resources } = itemPurchaseConstants;

export default function createInsufficientFundsModal() {
  const [Modal, modalService] = createModal();
  function InsufficientFundsModal({ translate, robuxNeeded, source }) {
    const body = (
      <div
        className='modal-message'
        dangerouslySetInnerHTML={{
          __html: translate(resources.insufficientFundsMessage, {
            robux: renderToString(<PriceLabel {...{ price: robuxNeeded }} />)
          })
        }}
      />
    );
    return (
      <Modal
        {...{
          title: translate(resources.insufficientFundsHeading),
          body,
          thumbnail: <span className='money-stack-icon' />,
          neutralButtonText: translate(resources.cancelAction),
          actionButtonText: translate(resources.buyRobuxAction),
          onAction: () => {
            window.location = urlConstants.getRobuxUpgradesUrl(source);
            return false;
          }
        }}
        actionButtonShow
      />
    );
  }
  InsufficientFundsModal.defaultProps = {
    onAccept: null,
    source: ''
  };
  InsufficientFundsModal.propTypes = {
    translate: PropTypes.func.isRequired,
    source: PropTypes.string,
    onAccept: PropTypes.func,
    robuxNeeded: PropTypes.number.isRequired
  };
  return [withTranslations(InsufficientFundsModal, translationConfig), modalService];
}
