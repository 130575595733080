import createTransactionFailureModal from './factories/createTransactionFailureModal';
import createInsufficientFundsModal from './factories/createInsufficientFundsModal';
import createPriceChangedModal from './factories/createPriceChangedModal';
import createPurchaseVerificationModal from './factories/createPurchaseVerificationModal';
import createPurchaseConfirmationModal from './factories/createPurchaseConfirmationModal';
import createItemPurchase from './factories/createItemPurchase';
import itemPurchaseConstants from './constants/itemPurchaseConstants';
import { getMetaData } from './util/itemPurchaseUtil';
import BalanceAfterSaleText from './components/BalanceAfterSaleText';
import PriceLabel from './components/PriceLabel';
import AssetName from './components/AssetName';
import '../../../css/itemPurchase/itemPurchase.scss';

const { errorTypeIds } = itemPurchaseConstants;

window.RobloxItemPurchase = {
  createTransactionFailureModal,
  createInsufficientFundsModal,
  createPriceChangedModal,
  createPurchaseVerificationModal,
  createPurchaseConfirmationModal,
  createItemPurchase,
  errorTypeIds,
  getMetaData,
  BalanceAfterSaleText,
  PriceLabel,
  AssetName
};
